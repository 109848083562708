import { ITimedMessage } from '@model/interfaces/timed-message.d';
import { SupplierService } from './../../../suppliers/services/supplier.service';
import { SupplierTimedMessageService } from './../../../suppliers/services/supplier-timed-messages.service';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { PurchaseOrderService } from '../../../purchase-orders/services/purchase-order.service';

@Component({
    templateUrl: './purchase-order-header.component.html',
})
export class PurchaseOrderHeaderComponent implements OnInit {
    header$: Observable<string>;
    timedMessages: ITimedMessage[] = [];
    today: Date;
    readonly purchaseOrderService = inject(PurchaseOrderService);
    readonly timedMessageService = inject(SupplierTimedMessageService);
    readonly supplierService = inject(SupplierService);
    readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        this.today = new Date();
        this.today.setHours(0, 0, 0, 0);
        const id = +this.route.snapshot.paramMap.get('purchaseOrderId');
        if (id > 0) {
            this.purchaseOrderService.getById(id).subscribe((purchaseOrder) => {
                this.purchaseOrderService.formatTitleText(purchaseOrder);
                if (purchaseOrder?.SupplierId > 0) {
                    this.timedMessageService.getTimedMessages(purchaseOrder.SupplierId).subscribe((tMessages) => {
                        this.timedMessages = tMessages.filter((t) => new Date(t.Date) >= this.today);
                    });
                }
            });
        } else {
            this.purchaseOrderService.setTitle('Add Purchase Order');
        }
        this.header$ = this.purchaseOrderService.title$;
        this.supplierService.supplierUpdateOnPO.subscribe((id) => {
            this.timedMessageService.getTimedMessages(id).subscribe((tMessages) => {
                this.timedMessages = tMessages.filter((t) => new Date(t.Date) >= this.today);
            });
        });
    }
}
