import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MoqAndDiscountTierEnum } from '@common/enums';
import { ICustomAddress } from '@model/interfaces/custom-address.d';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

import { BaseService } from '@mt-ng2/base-service';

import { ISupplier } from '@model/interfaces/supplier';
import { IItemSearchFilterElement } from 'admin-portal/items/services/item.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export const emptySupplier: ISupplier = {
    AccountPassword: null,
    AccountUsername: null,
    AccountWebsite: null,
    BundlingAndShippingCostNotes: '',
    CreditCardFee: null,
    CreditCardNotKeptOnFile: false,
    DefaultCreditCardId: null,
    DefaultOrderPlacementId: null,
    DefaultPaymentTermId: null,
    DefaultPaymentTypeId: null,
    DirectToFba: false,
    DisableTrackingEmails: false,
    DtaAddressId: null,
    FreeShipping: false,
    Id: 0,
    IsActive: true,
    MixnMatchMoq: false,
    MoqTierId: MoqAndDiscountTierEnum.Tier1,
    Name: '',
    OrderPassword: null,
    OrderUsername: null,
    OrderWebsite: null,
    RestrictUnitsAsCredit: false,
    ScharfPaysAndProvidesLabel: false,
    SupplierProvidesLabel: false,
    TrackingEmailInterval: 7,
    Website: null,
};

@Injectable({
    providedIn: 'root',
})
export class SupplierService extends BaseService<ISupplier> {
    supplierFieldUpdated = new Subject<void>();
    addSupplierUpdateForModalInItems = new Subject<number>();
    supplierUpdateOnPO = new Subject<number>();
    supplierUpdateOnItem = new Subject<number>();

    constructor(public http: HttpClient) {
        super('/Suppliers', http, null, { entityName: 'Supplier', titlePrefix: 'Supplier' });
    }

    getEmptySupplier(): ISupplier {
        return { ...emptySupplier };
    }

    toggleIsActive(supplierId: number): Observable<void> {
        return this.http.put<void>(`/Suppliers/ToggleIsActive`, supplierId);
    }

    saveDtaAddress(supplierId: number, address: ICustomAddress): Observable<number> {
        return this.http.put<number>(`/Suppliers/${supplierId}/${address.Id}`, address);
    }

    deleteDtaAddress(supplierId: number, addressId: number): Observable<object> {
        return this.http.delete(`/Suppliers/${supplierId}/${addressId}`, { responseType: 'text' as 'json' });
    }

    getMAPStrictValue(supplierId: number): Observable<string> {
        return this.http.get<string>(`/Suppliers/${supplierId}/GetMAPStrictValue`);
    }

    getSearchFilterItems(): Observable<MtSearchFilterItem[]> {
        return this.http.get<ISupplier[]>(`/Suppliers`).pipe(map((s) => s.map((x): MtSearchFilterItem => ({ Item: x, Selected: false }))));
    }
    getSearchFilterItemsByName(searchText: string, creditsSuppliersOnly?: boolean): Observable<IItemSearchFilterElement[]> {
        return this.http.get<IItemSearchFilterElement[]>(`/Suppliers/SearchFilterItemsByName/${searchText}`, {
            params: creditsSuppliersOnly !== undefined ? { creditsSuppliersOnly: creditsSuppliersOnly.toString() } : {},
        });
    }
}
