import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IPurchaseOrder } from '@model/interfaces/purchase-order';
import { Observable, Subject } from 'rxjs';
import { IPoIdDTO } from '@model/DTO/IPoIdDTO';
import { IPurchaseOrderDTO } from '@model/DTO/IPurchaseOrderDTO';

export const emptyPurchaseOrder: IPurchaseOrder = {
    Active: true,
    AmazonReceivingIssue: false,
    AvailableDate: null,
    CreatedById: null,
    CreditCardId: null,
    DateCreated: null,
    DisableTrackingEmails: false,
    EstimatedShippingCost: 0,
    FlexToPush: false,
    FreeShipping: false,
    Hold: false,
    Id: 0,
    IsMarkedComplete: false,
    LockedDate: false,
    NeedsCreditCard: false,
    Open: true,
    OrderDate: null,
    OrderPlaced: false,
    OrderReport: null,
    OverrideEstimatedShipping: false,
    PaymentTermId: null,
    PaymentTypeId: null,
    PoNotes: '',
    ShippingAddressId: null,
    ShippingMethodId: null,
    SkuVaultPoId: null,
    Snooze: null,
    SupplierId: 0,
    SupplierIssue: false,
    SupplierOrderNumber: null,
    WandDRequested: false,
};

@Injectable({
    providedIn: 'root',
})
export class PurchaseOrderService extends BaseService<IPurchaseOrder> {
    poFieldUpdated = new Subject<void>();
    estShippingUpdated = new Subject<void>();
    paymentTermChanges = new Subject<number>();

    constructor(public http: HttpClient) {
        super('/Pos', http, null, { entityName: 'Purchase Order' });
    }

    formatTitleText(purchaseOrder: IPurchaseOrder): void {
        this.setTitle(purchaseOrder?.TransferId ? `Transfer: ${purchaseOrder.TransferId}` : `Purchase Order: ${purchaseOrder.Id}`);
    }
    getByMatchingSupplier(supplierName: string): Observable<IPurchaseOrder[]> {
        return this.http.get<IPurchaseOrder[]>(`/Pos/BySupplierName?supplierName=${supplierName}`);
    }

    getEmptyPurchaseOrder(): IPurchaseOrder {
        return { ...emptyPurchaseOrder };
    }

    toggleIsActive(poId: number): Observable<void> {
        return this.http.put<void>(`/Pos/toggleIsActive`, poId);
    }

    checkForExistingPurchaseOrderWithSupplier(supplierId: number): Observable<number[]> {
        return this.http.get<number[]>(`/Pos/checkForSupplier/${supplierId}`);
    }

    createEmail(poId: number, userId: number, emailTypeId: number): Observable<any> {
        return this.http.post<any>(`/Pos/sendEmail/${userId}/${emailTypeId}`, poId);
    }

    getLockedOrderReportDate(): Observable<Date> {
        return this.http.get<Date>(`/Pos/lockedOrderReportDate`);
    }

    getPosNotPartOfOrderReport(date: string): Observable<IPurchaseOrder[]> {
        let params = new HttpParams();
        params = params.append('date', date);
        return this.http.get<IPurchaseOrder[]>(`/Pos/getPosNotPartOfOrderReport`, { params: params });
    }

    addOrderReportDateToPos(poIds: number[], date: string): Observable<null> {
        let params = new HttpParams();
        params = params.append('date', date);
        return this.http.put<null>(`/Pos/addOrderReportDateToPos`, poIds, { params: params });
    }

    markAsComplete(poId: number, createdById: number): Observable<void> {
        return this.http.get<void>(`/Pos/markAsComplete/${poId}/${createdById}`);
    }

    updatePaidDate(poId: number): Observable<void> {
        return this.http.get<void>(`/Pos/updatePaidDate/${poId}`);
    }

    getTotalEstimatedCost(po: IPurchaseOrder): Observable<number> {
        return this.http.post<number>(`/Pos/getPoEstimatedCost`, po);
    }

    updateEstimatedTotalCost(po: IPurchaseOrder): Observable<number> {
        return this.http.post<number>(`/Pos/updateEstimatedTotalCost`, po);
    }
    updateEstimatedTotalCostBulk(pos: IPurchaseOrderDTO[]): Observable<IPurchaseOrderDTO[]> {
        return this.http.put<IPurchaseOrderDTO[]>(`/Pos/updateEstimatedTotalCost/bulk`, pos);
    }
    updateOrderDatesForPOsToToday(poIds: number[]): Observable<void> {
        return this.http.post<void>(`/Pos/updateOrderDatesForPOsToToday`, poIds)
    }

    getAllBySupplierId(supplierId: number): Observable<IPurchaseOrder[]> {
        return this.http.get<IPurchaseOrder[]>(`/Pos/getBySupplier/${supplierId}`);
    }
    getAllByItemId(itemId: number): Observable<IPurchaseOrder[]> {
        return this.http.get<IPurchaseOrder[]>(`/Pos/ByItemId/${itemId}`);
    }
    getAllPoIdDTOs(): Observable<IPoIdDTO[]> {
        return this.http.get<IPoIdDTO[]>(`/Pos/getAllPoIdDTOs`);
    }
}
