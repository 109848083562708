import { IEntity } from '@mt-ng2/base-service';

export function nullOutFKs<T extends IEntity>(object: T): T {
    for (const prop in object) {
        if (typeof object[prop] === 'object') {
            object[prop] = null;
        }
    }
    return object;
}

export function onlyUnique<T>(value: T, index: number, array: T[]): boolean {
    return array.indexOf(value) === index;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseHttpError(err: any): string {
    if (err.error?.ModelState) {
        return err?.error?.ModelState[0] as string;
    } else {
        return (err?.error ? err.error : err) as string;
    }
}
