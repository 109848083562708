import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError } from 'rxjs/operators';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';
import { ITimedMessage } from '@model/interfaces/timed-message';
export const emptyTimedMessage: ITimedMessage = {
    Date: new Date(),
    Id: 0,
    Message: '',
    SupplierId: 0,
};
@Injectable({
    providedIn: 'root',
})
export class SupplierTimedMessageService extends BaseService<ITimedMessage> {
    constructor(public http: HttpClient) {
        super(`/timedMessages`, http);
    }

    getTimedMessages(supplierId: number): Observable<ITimedMessage[]> {
        return this.http.get<ITimedMessage[]>(`/suppliers/${supplierId}/timedMessages`);
    }

    createTimedMessage(supplierId: number, message: ITimedMessage): Observable<number> {
        return this.http.post<number>(`/suppliers/${supplierId}/timedMessages`, message);
    }

    updateTimedMessage(supplierId: number, message: ITimedMessage): Observable<number> {
        return this.http.put<number>(`/suppliers/${supplierId}/timedMessages`, message);
    }

    deleteTimedMessage(supplierId: number, timedMessageId: number): Observable<void> {
        return this.http.delete<void>(`/suppliers/${supplierId}/timedMessages/${timedMessageId}`).pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    getEmptyTimedMessage(): ITimedMessage {
        return emptyTimedMessage;
    }
}
