// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=testing` then `environment.testing.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    amazonSellerUrl: 'https://www.amazon.com/dp/<<INSERT SKU HERE>>/',
    apiVersion: '1',
    appName: 'Scharf',
    appVersion: '1.0.0',
    assetsPath: 'assets/',
    authClientId: 1,
    authSecretVariable: 'verysecret',
    baseApiUrl: '',
    docPath: 'docs/',
    googleApiKey: '', // Empty this string to disable google login
    imgPath: 'docs/images/',
    logger: false,
    production: false,
    siteName: 'scharf',
    submitHelpUrl: 'http://submit-help.testing.milesapp.com',
    zentailUrl: 'https://app.zentail.com/product#/filter/active__all/page/1/perPage/25/search/(**ITEM SKU**)/sort/quantity/sortDir/-1/view/catalog',
    useAmzV2024Flow: true,
};
