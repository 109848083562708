import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (this.isReportingModuleNoDatasetError(error)) {
                    return of(this.getDefaultResForReportingModuleDatasetsReq());
                }

                return throwError(() => error);
            }),
        );
    }

    isReportingModuleNoDatasetError(error: HttpErrorResponse): boolean {
      // An API request to this path is sent as soon as the page /my-reports/add is loaded. Since no dataset is selected initially in the UI, the API returns a 500 error since it can't find any datasets for the default ID 0. Since the NG module as well as the .NET API come from external libraries, and they don't expose any public APIs,  we can't change the behavior of the API. The error-handler module also does not expose any configuration options to suppress specific errors. And so, we suppress the error here, based on the following condition.
        const SUPPRESS_PATH = '/api/v1/reports/datasets/datapoints/0';

        const url = new URL(error.url);
        const path = url.pathname;

        return path === SUPPRESS_PATH;
    }

    getDefaultResForReportingModuleDatasetsReq() {
      // This will return a 200 response with an empty array as the body, which is what the reporting module expects when no datasets are found.
        return new HttpResponse({
            body: [],
        });
    }
}
