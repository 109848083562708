<div>
    <div class="row">
        <div class="col-md-7">
            <h2 [mtSetTitle]="header$">
                <mt-route-back-button></mt-route-back-button>
            </h2>
        </div>
        <div class="col-md-5">
            <h4 *ngIf="timedMessages && timedMessages.length" style="margin: 15px 0">Timed Messages</h4>
            <span *ngFor="let message of timedMessages">
                <p>{{ message.Message }}</p>
            </span>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
