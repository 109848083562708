
import { ClaimTypes } from '@model/ClaimTypes';
import { ReportUsersService } from '../services/report-users.service';
import { IReportingModuleConfig } from '@mt-ng2/advanced-reporting-module';

export const reportingModuleConfig: IReportingModuleConfig = {
    displayFiltersOnRenderedReport: true,
    reportingClaimTypeId: ClaimTypes.Reports,
    reportUsersService: <any>ReportUsersService,
};
