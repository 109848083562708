import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { AuthService } from '@mt-ng2/auth-module';

import { UserService } from '../../users/user.service';
import { IReportUserRole, ICurrentReportUser, IReportUsersService, IReportUser } from '@mt-ng2/advanced-reporting-module';

@Injectable({ providedIn: 'root' })
export class ReportUsersService implements IReportUsersService {
    currentReportUser: BehaviorSubject<ICurrentReportUser> = new BehaviorSubject(null);

    constructor(private http: HttpClient, private userService: UserService, private authService: AuthService) {
        const currentUser = this.authService.currentUser.getValue();
        if (currentUser && currentUser.Id) {
            this._setUserInfo(currentUser.Id);
        }
        this.authService.currentUser.subscribe((user) => {
            if (user && user.Id) {
                this._setUserInfo(user.Id);
            } else {
                this.currentReportUser.next(null);
            }
        });
    }

    getUsers(): Observable<IReportUser[]> {
        return this.http.get<IReportUser[]>(`/users/active`);
    }

    getUserRoles(): Observable<IReportUserRole[]> {
        return this.http.get<IReportUserRole[]>(`/userRoles`);
    }

    private _setUserInfo(userId: number): void {
        // setTimeout is needed to ensure that we don't hit this too quickly
        // and receive an UnAuthorized response from the API
        setTimeout(() => {
            this.userService.getById(userId).subscribe((user) => {
                const reportUser: ICurrentReportUser = {
                    Email: user.Email,
                    Id: user.Id,
                    Name: `${user.FirstName} ${user.LastName}`,
                    RoleId: user.AuthUser.RoleId,
                };
                this.currentReportUser.next(reportUser);
            });
        }, 0);
    }
}
