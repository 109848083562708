export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    Customers_CanChangeStatus = 5,
    UserRoles = 4,
    Audit = 6,
    SupplierManagedLists = 8,
    POManagedLists = 9,
    SupplierCategories = 10,
    CreditCards = 11,
    Suppliers = 12,
    SupplierAccountInfo = 13,
    ShippingAddresses = 14,
    Items = 15,
    Bundles = 16,
    POs = 17,
    ItemManagedLists = 18,
    OrderReport = 19,
    ExpectedPaymentDate = 20,
    FlexToPush = 21,
    AssignTo = 22,
    Transfers = 23,
    Receiving = 24,
    UnReceiving = 25,
    Outbounding = 26,
    UnOutbounding = 27,
    Reports = 28,
    Credits = 31,
    Returns = 32,
    Zentail_Dataset = 33,
    ZentailOrders_Dataset = 34,
    ZentailOrderItems_Dataset = 35,
    ZentailWarehouseItems_Dataset = 36,
    ZentailWarehouseListedItems_Dataset = 37,
    SystemPasswords = 38,
    Outbounding_ShipManually = 39,
    Amazon_Shipping=40,
    CronJobs=41,
    Users_PartialAccess=42,
}
