import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'substringPipe' })
export class SubstringPipe implements PipeTransform {
  transform(str: string, max: number): string {
    return (!str)
        ? ''
        : str.length > max
        ? str.substring(0, max) + '...'
        : str;
  }
}
