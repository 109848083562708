import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityListPaginationComponent } from '@mt-ng2/entity-list-module';

@Component({
    selector: 'custom-pagination',
    template: `
        <div class="pagination-container">
        <div class="pagination-input">
        <input type="number" class="page-input" [(ngModel)]="pageNumberInput" (change)="goToPage()" min="1" [max]="totalPages" />
        <span class="bold">/ {{ totalPages }}</span>
        </div>
        <ngb-pagination
            [collectionSize]="total"
            [(page)]="currentPage"
            [pageSize]="itemsPerPage"
            [maxSize]="5"
            [boundaryLinks]="true"
            [rotate]="true"
            (pageChange)="onPageChange($event)"
        ></ngb-pagination>
    </div>
    `,
    styles: [`
     .pagination-container {
         display: flex;
         align-items: center;
     }

     .pagination-container input[type="number"] {
         width: 50px;
         padding: 4px;
         border: 2px solid #007bff;
         border-radius: 4px;
         font-size: 16px;
         text-align: center;
         margin-right: 10px;
     }

     .pagination-container input[type="number"]:focus {
         outline: none;
         border-color: #0056b3;
     }

     .pagination-input {
        margin-right: 30px;
     }

     .bold {
         font-weight: bold;
     }
 `]
})
export class CustomPaginationComponent extends EntityListPaginationComponent {
    @Input() total!: number;
    @Input() itemsPerPage!: number;
    @Input() currentPage!: number;
    @Output() currentPageChange = new EventEmitter<number>();
    @Output() pageChange = new EventEmitter<number>();

    pageNumberInput = 1;

    goToPage() {
        if (this.pageNumberInput >= 1 && this.pageNumberInput <= this.totalPages) {
            this.currentPage = this.pageNumberInput;
            this.currentPageChange.emit(this.currentPage);
            this.pageChange.emit(this.currentPage);
        } else {
            // Handle invalid page number input
            alert("Invalid page number input.");
        }
    }

    onPageChange(pageNumber: number) {
        this.pageNumberInput = pageNumber; // Update the input field when page changes
        this.currentPageChange.emit(pageNumber);
        this.pageChange.emit(pageNumber);
    }

    get totalPages(): number {
        return Math.ceil(this.total / this.itemsPerPage);
    }
}
