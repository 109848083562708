import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { IModalOptions } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-stackable-modal',
    templateUrl: './stackable-modal.component.html',
    styleUrls: ['./stackable-modal.component.css'],
})
export class StackableModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() title = '';
    @Input() options: IModalOptions = {};
    @Input() customPopupStyles: Record<string, string> = {};
    @Input() customBackdropStyles: Record<string, string> = {};
    @Output() onClose = new EventEmitter<void>();
    @Output() onConfirm = new EventEmitter<void>();

    @ViewChild('modalDiv', { static: true }) modalDiv: ElementRef<HTMLDivElement> | undefined;

    styles: Record<string, string | number> = {};

    constructor(private renderer: Renderer2) {}

    ngOnInit(): void {
        this.styles = {
            width: this.options?.width || '50vw',
            ...this.customPopupStyles,
        };
    }

    ngAfterViewInit(): void {
        this.appendToBody();
    }

    private appendToBody() {
        if (this.modalDiv?.nativeElement) {
            // Use Renderer2 to append the element to the body
            this.renderer.appendChild(document.body, this.modalDiv.nativeElement);
        }
    }

    ngOnDestroy(): void {
        if (this.modalDiv?.nativeElement) {
            // Use Renderer2 to remove the element from the body
            this.renderer.removeChild(document.body, this.modalDiv.nativeElement);
        }
    }
}
