export enum CountriesEnum {
    US = 'US',
}

export enum ActionStatusEnum {
    Open = 1,
    Closed = 2,
}

export enum ActionTypeEnum {
    Bundle = 1,
    Item = 2,
    PurchaseOrder = 3,
    Supplier = 4,
    Credit = 5,
}

export enum CustomManagedListsEnum {
    SupplierAlternateNames = 'Supplier Alternate Names',
    SupplierTrackingEmails = 'Supplier Tracking Emails',
    SupplierOrderEmails = 'Supplier Order Emails',
    WeightDimensionEmails = 'Weight & Dimension Emails',
}

export enum MoqAndDiscountTierEnum {
    Tier1 = 1,
    Tier2 = 2,
    Tier3 = 3,
    Tier4 = 4,
}

export enum ItemMoqAndDiscountTierEnum {
    Tier1 = 1,
    Tier2 = 2,
}

export enum MapOptionsEnum {
    All = 1,
    Yes = 2,
    No = 3,
}

export enum UserRolesEnum {
    Admin = 1,
    User = 2,
    PoUser = 3,
    OrderPlacer = 4,
}

export enum ManagedActionsEnum {
    NeedsCC = 1,
    POCancelled = 2,
    POUnplaced = 3,
    POSetInactive = 4,
    POItemQtyChanged = 5,
    POItemDeleted = 6,
    SKUVaultPOCreated = 7,
    AmazonSKUReject = 8,
    UnReceiveSKU = 9,
    ReceiveSKU = 10,
    UnOutboundSKU = 11,
    CallsForTracking = 12,
    AmazonReceiveIssue = 13,
    SupplierIssue = 14,
    ItemSkuDisabled = 15,
    BundleSetInactive = 16,
    OverrideAdjustment = 17,
    MissedPayment = 18,
    POItemSKUChanged = 22,
}

export enum OrderPlacementEnum {
    Email = 1,
    Website = 2,
}

export enum SettingsEnum {
    PONotes = 1,
    POTerms = 2,
    LockedOrderDate = 3,
}

export enum EmailTypeEnum {
    StandardPoEmail = 1,
    WeightAndDim = 2,
    POCompleteEmail = 3,
}

export enum SelectShipmentTypeEnum {
    Last = 1,
    Next = 2,
    Select = 3,
}

export enum PaymentTypesEnum {
    CreditCard = 1,
    ACH = 2,
}

export enum PriorityTypesEnum {
    Normal = 1,
    High = 2,
}

export enum RecallMethodsEnum {
    Recall = 1,
    Multichannel = 2,
    NA = 3,
}

export enum TransferTypesEnum {
    WHtoFBA = 1,
    FBAtoWH = 2,
    FBAtoFBA = 3,
    RecallToInspect = 4,
}

export enum TransferStatusEnum {
    Open = 1,
    WaitingOnAvailableDate = 2,
    RecalledToInspect = 3,
    Completed = 4,
    Cancelled = 5,
}

export enum PurchaseOrderOrTransferMetaEnum {
    Transfer = 1,
    PurchaseOrder = 2,
}

export enum CreditApplicationsEnum {
    Check = 1,
    WireACH = 2,
    Units = 3,
    CreditCard = 4,
    CreditMemo = 5,
}

export enum CreditStagesEnum {
    InitialIssue = 1,
    InDiscussion = 2,
    Accepted = 3,
    Rejected = 4,
    Chargeback = 5,
    RefundReceived = 6,
    ClosedSpecial = 7,
}

export enum ReturnStatusesEnum {
    Sellable = 1,
    AmazonCase = 2,
    ListedAsUsed = 3,
    AmazonCaseOrScrapped = 4,
    NeedsMfgBox = 5,
    NeedsUlineBox = 6,
    Rma = 7,
    NeedsParts = 8,
    Scrapped = 9,
}

export enum DefaultReturnNotesEnum {
    DepletedContents = 1,
    Missing = 2,
    Broken = 3,
    Damaaged = 4,
    Defective = 5,
    PartsNeeded = 6,
    SerialNumber = 7,
}

export enum ReturnScannerStepsEnum {
    ScanTrackingNumber = 1,
    ScanLPN = 2,
    ConfirmItem = 3,
    SelectHowToSendSku = 4,
    SelectDifferentSku = 5,
    PrintLabel = 6,
    SetStatus = 7,
    AddNotes = 8,
    TakePictures = 9,
    UpdateSkuVaultLocation = 10,
    SaveReturn = 11,
}

export enum ContactTypesEnum {
    ArBilling = 1,
    Rma = 2,
    Parts = 3,
}

export enum ReturnImageTypesEnum {
    ShippingLabel = 1,
    PackingSlip = 2,
    ShippingBox = 3,
    Itembox = 4,
    Item = 5,
}

export enum ReturnShipmentStepsEnum {
    Dimensions = 1,
    Items = 2,
    Weight = 3,
}

export enum ReturnShipmentStatesEnum {
    Voided = 'VOIDED',
    Voiding = 'VOIDING',
    ErrorVoiding = 'ERROR_IN_VOIDING',
    Error = 'ERROR',
}

export enum YesNoOptionsEnum {
    Yes = 1,
    No = 2,
}
export enum ShippingAddressEnum{
    NonDTA = 1,
    DTA = 2,
}

export enum MerchantsEnum {
    SELLER = 1,
    AMAZON = 2,
}
