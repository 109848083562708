import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { nullOutFKs } from '@common/libraries/common-functions.library';
import { IPoSetting } from '@model/interfaces/po-setting';
import { ISetting } from '@model/interfaces/setting.d';
import { IMetaItem, MetaItemService } from '@mt-ng2/base-service';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SettingsService extends MetaItemService<ISetting> {
    updateApiTestMode = new Subject<boolean>();
    isInApiTestMode = false;
    constructor(public http: HttpClient) {
        super('SettingsService', 'Settings', 'SettingIds', '/Settings', http);
    }

    setApiTestMode(isTestMode: boolean): void {
        this.isInApiTestMode = isTestMode;
        this.updateApiTestMode.next(isTestMode);
    }

    getDefaultPoAssignee(): Observable<IMetaItem> {
        return this.http.get<IMetaItem>(`/Settings/default-po-assignee`);
    }

    getPoSettings(): Observable<IPoSetting> {
        return this.http.get<IPoSetting>(`/Settings/po-settings`);
    }

    updatePoSettings(poSettings: IPoSetting): Observable<IPoSetting> {
        let clone = JSON.parse(JSON.stringify(poSettings));
        clone = nullOutFKs(clone);
        return this.http.put<IPoSetting>(`/Settings/po-settings`, clone);
    }
}
